<template>
	<ScrollingDocument
		v-slot:default="{ page, isPageFocused, isElementFocused }"
		class="pdf-document pdf-grab"
		:pages="pages"
		:page-count="pageCount"
		:current-page="currentPage"
		:enable-page-jump="true"
		@page-jump="onPageJump"
		@pages-fetch="onPagesFetch"
		@pages-reset="fitHeight"
	>
		<PDFPage
			:scale="scale"
			:optimal-scale="optimalScale"
			:page="page"
			:is-page-focused="isPageFocused"
			:is-element-focused="isElementFocused"
			@page-rendered="onPageRendered"
			@page-errored="onPageErrored"
			@page-focus="onPageFocused"
		/>
	</ScrollingDocument>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import PDFPreview from '@/helpers/PDFPreview'

export default {
	name: 'PDFDocument',
	components: {
		ScrollingDocument: () => ({
			component: import('@/components/Documents/Signature/Preview/FilePreview/ScrollingDocument')
		}),
		PDFPage: () => ({
			component: import('@/components/Documents/Signature/Preview/FilePreview/PDFPage')
		})
	},
	mixins: [SignatureModuleGuard],
	props: {
		pages: {
			required: true,
			type: Array
		},
		pageCount: {
			required: false,
			type: Number,
			default: 0
		},
		scale: {
			required: false,
			type: Number,
			default: 1.0
		},
		optimalScale: {
			required: false,
			type: Number,
			default: 1
		},
		fit: {
			required: false,
			type: String,
			default: null
		},
		currentPage: {
			required: false,
			type: Number,
			default: 1
		},
		isPreviewEnabled: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		defaultViewport: function () {
			if (!this.pages.length) {
				return { width: 0, height: 0 }
			} else {
				const [page] = this.pages
				return page.getViewport({ scale: 1.0 })
			}
		},
		isPortrait: function () {
			const { width, height } = this.defaultViewport
			return width <= height
		}
	},
	watch: {
		fit: function (fit) {
			switch (fit) {
				case 'width':
					this.fitWidth()
					break
				case 'height':
					this.fitHeight()
					break
				case 'auto':
					this.fitAuto()
					break
				default:
					this.fitAuto()
					break
			}
		}
	},
	methods: {
		pageWidthScale: function () {
			const { defaultViewport, $el } = this
			if (!defaultViewport.width) {
				return 0
			}
			return ($el.clientWidth * PDFPreview.constants.PIXEL_RATIO) / defaultViewport.width
		},
		pageHeightScale: function () {
			const { defaultViewport, $el } = this
			if (!defaultViewport.height) {
				return 0
			}
			return ($el.clientHeight * PDFPreview.constants.PIXEL_RATIO) / defaultViewport.height
		},
		fitAuto: function () {
			const scale = Math.min(this.pageWidthScale(), this.pageHeightScale())
			this.updateScale(scale, { isOptimal: !this.optimalScale })
		},
		fitHeight: function () {
			const scale = this.isPortrait ? this.pageHeightScale() : this.pageWidthScale()
			this.updateScale(scale, { isOptimal: !this.optimalScale })
		},
		updateScale: function (scale, { isOptimal = false } = {}) {
			if (!scale) {
				return
			}
			this.$emit('scale-change', { scale, isOptimal })
		},
		onPageJump: function (scrollTop) {
			this.$el.scrollTop = scrollTop
		},
		onPagesFetch: function (currentPage) {
			this.$parent.$emit('pages-fetch', currentPage)
		},
		onPageFocused: function (pageNumber) {
			this.$emit('page-focus', pageNumber)
		},
		onPageRendered: function (payload) {
			this.$parent.$emit('page-rendered', payload)
		},
		onPageErrored: function (payload) {
			this.$parent.$emit('page-errored', payload)
		}
	}
}
</script>

<style>
.pdf-document {
	position: absolute;
	overflow: auto;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #525f69;
}

.pdf-grab {
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: grab;
}

.pdf-grab:active {
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: grabbing;
}

.scrolling-page {
	margin-bottom: 1em;
}

@media print {
	.pdf-document {
		position: static;
	}
}
</style>
